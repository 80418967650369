<template>
  <!-- My 포인트 -->
  <coach-my-page-content-layout user-point>
    <my-page-title>
      <template #left>
        <span>MY 포인트</span>
        <point-icon shape="white" :value="upBalancePoint" />
      </template>
      <template #right>
        <color-button type="purple" @click="onRecharge" class="btn-recharge">충전하기</color-button>
        <point-icon shape="white" :value="upBalancePoint" class="mobile" />
      </template>
    </my-page-title>
    <CoTable rowKey="cashTransactionId" :value="transactionItems">
      <TableColumn key="a" field="transactionType" title="구분" align="center" :width="168" :render="renderType" />
      <TableColumn key="b" field="description" title="내용" :width="200" />
      <TableColumn key="c" field="cashAmount" title="포인트 증/감" align="center" :width="100" :render="renderAmount" />
      <TableColumn key="d" field="remainBalance" title="잔여 포인트" align="center" :width="117" />
      <TableColumn key="e" field="createdDatetime" title="날짜" align="center" :width="96" :render="renderDate" />
    </CoTable>
    <no-content v-if="!hasContent" />
    <div class="area-bottom">
      <Pagination v-if="!loading" :chunk-size="listSize" :info="transactionInfo" theme="coaching" @change="changeCursor" />
      <Selector :list="listSizes" :value="listSize" class="selector-listsize" theme="white-reverse" @change="onListSizeChange" />
    </div>
    <color-button type="purple" @click="onRecharge" class="btn-recharge-mobile">충전하기</color-button>
  </coach-my-page-content-layout>
</template>

<script>
import cursorFetcher from 'shared/mixins/cursorFetcher';
import ColorButton from '@shared/components/common/ColorButton.vue';
import { getShortYMD } from 'shared/utils/timeUtils';
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout.vue';
import MyPageTitle from '@/views/components/coaching/coach-my-page/MyPageTitle.vue';
import PointIcon from '@/views/components/coaching/PointIcon.vue';
import TableColumn from '@/views/components/coaching/sales/TableColumn.vue';
import TransactionTypeBadge from '@/views/components/coaching/coach-my-page/TransactionTypeBadge';
import Pagination from '@/views/components/common/Pagination';
import Selector from '@/views/components/coaching/Selector';
import NoContent from '@/views/components/coaching/question-detail/NoContent';
import PointBuyModal from '@/views/components/coaching/PointBuyModal.vue';
import CoTable from '@/views/components/coaching/sales/CoTable.vue';

export default {
  name: 'UserPoint',
  components: { NoContent, Selector, Pagination, TableColumn, PointIcon, MyPageTitle, CoachMyPageContentLayout, ColorButton, CoTable },
  lexicon: 'coaching.settlementHistory',
  data: () => ({
    transactionInfo: /** @type {PagedTransactions} */ {},
    loading: false,
  }),
  mixins: [cursorFetcher({
    transactionInfo: async ({ services, size, page }) => {
      /**
       * @type {PagedTransactions}
       */
      const result = await services.coaching.getTransactions({ cursor: page, size });
      return result;
    },
  })],
  computed: {
    upBalancePoint() {
      return this.$store.state?.auth?.myInfo?.wallet?.upBalanceAmount ?? 0;
    },
    transactionItems() {
      return this.transactionInfo?.items ?? [];
    },
    hasContent() {
      return this.transactionItems?.length >= 1;
    },
    listSize: {
      get() {
        return this.$route?.query?.size ? Math.round(this.$route?.query?.size) : 10;
      },
      set(size) {
        this.$router.push({ query: this.getMergedQuery({ size, page: 0 }) });
      },
    },
    listSizes() {
      return [100, 50, 25, 10].map(value => ({ value, label: this.$t('_.listSize', [value]) }));
    },
    page: {
      get() {
        return Math.round(this.$route.query?.page ?? 0);
      },
      async set(page) {
        this.$router.push({ query: this.getMergedQuery({ page }) });
        this.transactionInfo.cursor = page;
      },
    },
  },
  methods: {
    getPlusMinus(n) {
      if (n < 0) return `-${n}`;
      if (n === 0) return '0';
      return `+${n}`;
    },
    renderAmount({ row }, h) {
      return h('span', this.getPlusMinus(row.cashAmount));
    },
    renderDate({ row }, h) {
      return h('span', getShortYMD(row.createdDatetime));
    },
    renderType({ row }, h) {
      return h(TransactionTypeBadge, { props: { value: row.transactionType } });
    },
    onListSizeChange(arg) {
      if (typeof arg === 'number') this.listSize = arg;
    },
    changeCursor(cursor) {
      this.page = cursor;
    },
    onRecharge() {
      // this.$modal(PointBuyModal);
      alert('포인트 충전이 불가합니다.');
    },
  },
  async asyncData({ mixinFetcher, services, route, store }) {
    const page = route.query.page ?? 0;
    const size = route.query.size ?? 10;
    const sort = route.query.sort ?? '';
    const sortBy = route.query.sortBy ?? '';
    return { ...await mixinFetcher.fetch({ store, route, services, defaultParams: { page, size, sort, sortBy } }) };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[user-point] {
  [status-badge] {.w(59) !important;display: inline-block;}
  .area-bottom {.mt(20);.rel;
    [selector] {.abs;.r(0);.t(0)}
  }
  [custom-table] {.mt(50);overflow-x: scroll;
    .ve-table-container {.min-w(680)}
  }
  .ve-table-header-th:nth-of-type(2) { .min-w(200);}
  .btn-recharge-mobile {display: none;}
  [point-icon].mobile {display: none;}
  @media (@tl-down) {
    .btn-recharge { .hide}
    [point-icon] {.hide}
    .area-bottom [selector] {.w(130);.ml(20);.mr(20)}
    [pagination] {.mb(43);.pt(65)}
    .btn-recharge-mobile {display: block;.m(auto)}
    [point-icon].mobile {display: inline-block;}
  }
}
</style>
